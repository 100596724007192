import React from 'react'
import GatsbyLink from '../GatsbyLink'
import './Breadcrumbs.scss'

export const Breadcrumbs = props => {
  const { location, overwrite } = props
  const { pathname, origin } = location

  if (overwrite) {
    return (
      <div className="breadcrumbs">
        <div className="inner">
          <ul itemScope itemType="http://schema.org/BreadcrumbList">
            <li
              itemProp="itemListElement"
              itemType="http://schema.org/ListItem"
            >
              <GatsbyLink to={origin}>Home</GatsbyLink>
            </li>
            {overwrite &&
              overwrite.map(
                (item, index) =>
                  item.title && (
                    <li
                      key={index}
                      itemProp="itemListElement"
                      itemType="http://schema.org/ListItem"
                    >
                      <span>/</span>
                      {item.url ? (
                        <GatsbyLink to={item.url} decode={true}>
                          {item.title}
                        </GatsbyLink>
                      ) : (
                        <span
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                      )}
                    </li>
                  )
              )}
          </ul>
        </div>
      </div>
    )
  }

  if (!location) {
    return null
  }

  const pathArray = pathname.split('/')
  let urlBuild = [origin]

  return (
    <div className="breadcrumbs">
      <div className="inner">
        <ul itemScope itemType="http://schema.org/BreadcrumbList">
          <li itemProp="itemListElement" itemType="http://schema.org/ListItem">
            <GatsbyLink to={origin}>Home</GatsbyLink>
          </li>
          {pathArray &&
            pathArray.map((item, index) => {
              if (item) {
                urlBuild.push(item)
                return (
                  <li
                    key={index}
                    itemProp="itemListElement"
                    itemType="http://schema.org/ListItem"
                  >
                    <span>/</span>
                    {item === 'category' ||
                    item === 'products' ||
                    item === 'product-category' ||
                    item === 'page' ? (
                      <span>{item.replace('-', ' ')}</span>
                    ) : (
                      <GatsbyLink to={`${urlBuild.join('/')}/`} decode={true}>
                        {item.replace('-', ' ')}
                      </GatsbyLink>
                    )}
                  </li>
                )
              }
            })}
        </ul>
      </div>
    </div>
  )
}
